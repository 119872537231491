import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Image, Section, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				STARGAZE EVENTS
			</title>
			<meta name={"description"} content={"Добро пожаловать на мероприятия StarGaze"} />
			<meta property={"og:title"} content={"STARGAZE EVENTS"} />
			<meta property={"og:description"} content={"Добро пожаловать на мероприятия StarGaze"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} />
		</Helmet>
		<Components.Header2>
			<Override slot="text" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
		</Components.Header2>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-15">
			<Override slot="SectionContent" flex-wrap="wrap" flex-direction="row" />
			<Box
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 0px 16px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				lg-width="100%"
				justify-content="space-between"
			>
				<Box display="flex" flex-direction="column">
					<Text
						margin="0px 0px 36px 0px"
						color="--darkL2"
						font="--headline2"
						lg-text-align="center"
						sm-font="--headline3"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
						md-text-align="left"
					>
						Наша миссия
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--darkL2"
						font="--base"
						lg-text-align="center"
						md-text-align="left"
					>
						Наша миссия в StarGaze Events — превратить каждое мероприятие в незабываемое событие. Благодаря команде увлеченных и творческих профессионалов мы стремимся реализовать ваше видение, заботясь о каждой детали, чтобы обеспечить идеальное мероприятие. Будь то сказочная свадьба, успешная корпоративная вечеринка или интимное торжество, мы стремимся превзойти ваши ожидания, предоставив высококачественные и персонализированные услуги.
					</Text>
					<Link
						href="/services"
						padding="12px 24px 12px 24px"
						color="--light"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="2rem 16px 0px 0px"
						transition="background-color 0.2s ease-in-out 0s"
						sm-margin="0px 0px 16px 0px"
						sm-text-align="center"
						background="--color-primary"
						hover-transition="background-color 0.2s ease-in-out 0s"
						hover-background="--color-primary"
						text-align="center"
					>
						Готовы спланировать идеальное мероприятие?
					</Link>
				</Box>
				<Box width="100%" display="flex" sm-flex-wrap="wrap">
					<Box
						display="flex"
						width="25%"
						lg-width="100%"
						margin="0px 0px 0px 0px"
						padding="16px 16px 16px 16px"
						flex-direction="column"
						sm-width="50%"
					>
						<Box
							overflow-x="hidden"
							overflow-y="hidden"
							position="relative"
							transform="translateY(0px)"
							hover-transform="translateY(-10px)"
							width="100%"
							height="auto"
							transition="transform 0.2s ease-in-out 0s"
							padding="0px 0px 125% 0px"
						>
							<Image
								width="100%"
								left={0}
								src="https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-3.jpg?v=2024-06-10T08:22:40.334Z"
								object-fit="cover"
								position="absolute"
								display="block"
								top="auto"
								right={0}
								bottom="0px"
								min-height="100%"
								srcSet="https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-3.jpg?v=2024-06-10T08%3A22%3A40.334Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-3.jpg?v=2024-06-10T08%3A22%3A40.334Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-3.jpg?v=2024-06-10T08%3A22%3A40.334Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-3.jpg?v=2024-06-10T08%3A22%3A40.334Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-3.jpg?v=2024-06-10T08%3A22%3A40.334Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-3.jpg?v=2024-06-10T08%3A22%3A40.334Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-3.jpg?v=2024-06-10T08%3A22%3A40.334Z&quality=85&w=3200 3200w"
								sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
							/>
						</Box>
					</Box>
					<Box
						width="25%"
						lg-width="100%"
						margin="0px 0px 0px 0px"
						padding="16px 16px 16px 16px"
						flex-direction="column"
						sm-width="50%"
						display="flex"
					>
						<Box
							width="100%"
							height="auto"
							position="relative"
							padding="0px 0px 125% 0px"
							overflow-x="hidden"
							overflow-y="hidden"
							transform="translateY(0px)"
							transition="transform 0.2s ease-in-out 0s"
							hover-transform="translateY(-10px)"
						>
							<Image
								display="block"
								width="100%"
								top="auto"
								right={0}
								position="absolute"
								object-fit="cover"
								left={0}
								bottom="0px"
								min-height="100%"
								src="https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-2.jpg?v=2024-06-10T08:22:40.331Z"
								srcSet="https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-2.jpg?v=2024-06-10T08%3A22%3A40.331Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-2.jpg?v=2024-06-10T08%3A22%3A40.331Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-2.jpg?v=2024-06-10T08%3A22%3A40.331Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-2.jpg?v=2024-06-10T08%3A22%3A40.331Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-2.jpg?v=2024-06-10T08%3A22%3A40.331Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-2.jpg?v=2024-06-10T08%3A22%3A40.331Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-2.jpg?v=2024-06-10T08%3A22%3A40.331Z&quality=85&w=3200 3200w"
								sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
							/>
						</Box>
					</Box>
					<Box
						flex-direction="column"
						sm-width="50%"
						display="flex"
						width="25%"
						lg-width="100%"
						margin="0px 0px 0px 0px"
						padding="16px 16px 16px 16px"
					>
						<Box
							overflow-x="hidden"
							overflow-y="hidden"
							position="relative"
							transition="transform 0.2s ease-in-out 0s"
							hover-transform="translateY(-10px)"
							height="auto"
							transform="translateY(0px)"
							padding="0px 0px 125% 0px"
							width="100%"
						>
							<Image
								object-fit="cover"
								width="100%"
								right={0}
								bottom="0px"
								min-height="100%"
								src="https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-1.jpg?v=2024-06-10T08:22:40.319Z"
								position="absolute"
								display="block"
								top="auto"
								left={0}
								srcSet="https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-1.jpg?v=2024-06-10T08%3A22%3A40.319Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-1.jpg?v=2024-06-10T08%3A22%3A40.319Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-1.jpg?v=2024-06-10T08%3A22%3A40.319Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-1.jpg?v=2024-06-10T08%3A22%3A40.319Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-1.jpg?v=2024-06-10T08%3A22%3A40.319Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-1.jpg?v=2024-06-10T08%3A22%3A40.319Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/1-1.jpg?v=2024-06-10T08%3A22%3A40.319Z&quality=85&w=3200 3200w"
								sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
							/>
						</Box>
					</Box>
					<Box
						display="flex"
						width="25%"
						lg-width="100%"
						margin="0px 0px 0px 0px"
						padding="16px 16px 16px 16px"
						flex-direction="column"
						sm-width="50%"
					>
						<Box
							position="relative"
							transition="transform 0.2s ease-in-out 0s"
							width="100%"
							height="auto"
							overflow-y="hidden"
							padding="0px 0px 125% 0px"
							overflow-x="hidden"
							transform="translateY(0px)"
							hover-transform="translateY(-10px)"
						>
							<Image
								object-fit="cover"
								position="absolute"
								width="100%"
								left={0}
								bottom="0px"
								src="https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"
								display="block"
								top="auto"
								right={0}
								min-height="100%"
								srcSet="https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08%3A22%3A40.317Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08%3A22%3A40.317Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08%3A22%3A40.317Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08%3A22%3A40.317Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08%3A22%3A40.317Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08%3A22%3A40.317Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08%3A22%3A40.317Z&quality=85&w=3200 3200w"
								sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
							/>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
			>
				<Box
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					position="relative"
					transform="translateY(0px)"
					padding="0px 0px 90% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
				>
					<Image
						src="https://images.unsplash.com/photo-1613109040825-b5f764066305?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						object-fit="cover"
						left={0}
						right={0}
						min-height="auto"
						position="absolute"
						display="block"
						width="100%"
						top="auto"
						bottom="0px"
						height="100%"
						srcSet="https://images.unsplash.com/photo-1613109040825-b5f764066305?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1613109040825-b5f764066305?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1613109040825-b5f764066305?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1613109040825-b5f764066305?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1613109040825-b5f764066305?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1613109040825-b5f764066305?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1613109040825-b5f764066305?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-dark" display="block">
			<Override slot="SectionContent" />
			<Text margin="0px 0px 0px 0px" color="--light" font="--headline2" md-margin="0px 0px 30px 0px">
				Наш подход
			</Text>
			<Box
				width="100%"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--light"
					font="--base"
					md-margin="0px 0px 20px 0px"
					opacity="1"
				>
					Наш подход основан на сотрудничестве и индивидуальном подходе. Мы тесно сотрудничаем с вами, чтобы понять ваши желания и потребности, создавая подробный план, отражающий вашу индивидуальность и стиль. От первоначального планирования до окончательной реализации, каждый этап процесса выполняется с профессионализмом и вниманием к деталям, что обеспечивает незабываемое и беззаботное мероприятие.
				</Text>
			</Box>
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
				margin="2rem 0px 0px 0px"
			>
				<Text margin="0px 0px 0px 0px" color="--light" font="--headline2" md-margin="0px 0px 30px 0px">
					Опыт и страсть
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--light"
					font="--base"
					md-margin="0px 0px 20px 0px"
					opacity="1"
				>
					Команда StarGaze Events состоит из экспертов event-индустрии с многолетним опытом и бесконечной страстью к созданию волшебных моментов. В нашу команду входят организаторы мероприятий, дизайнеры, координаторы и специалисты по кейтерингу, каждый из которых стремится предоставить исключительный сервис. Наш опыт позволяет нам решать любые задачи творчески и компетентно, гарантируя успех вашего мероприятия.
				</Text>
			</Box>
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				margin="2rem 0px 0px 0px"
			>
				<Text margin="0px 0px 0px 0px" color="--light" font="--headline2" md-margin="0px 0px 30px 0px">
					Сотрудничество и творчество
				</Text>
				<Text margin="0px 0px 40px 0px" color="--light" font="--lead">
					Мы верим в силу сотрудничества и творчества. Каждый член нашей команды привносит уникальный взгляд и набор навыков, которые обогащают процесс планирования. Мы работаем вместе, делимся идеями и вдохновением, чтобы создавать не только красивые, но и значимые события. Наш творческий потенциал позволяет нам предлагать инновационные и персонализированные решения, делая каждое мероприятие уникальным.
				</Text>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" background="--color-light" quarkly-title="FAQ-10">
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 600 36px/1.2 --fontFamily-sans" color="--darkL1">
						FAQ
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="block"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="50px 50px"
					md-grid-template-columns="1fr"
					sm-grid-gap="35px 0"
					padding="0px 0px 0px 70px"
					lg-padding="0px 0px 0px 0"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							1. Какие мероприятия вы организуете?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Мы организуем широкий спектр мероприятий, включая свадьбы, корпоративы, дни рождения, благотворительные акции, конференции и многое другое. Каждое мероприятие мы адаптируем в соответствии с потребностями и желаниями наших клиентов.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							2. Предлагаете ли вы комплексные пакеты планирования или я могу выбрать только несколько услуг?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Мы предлагаем как комплексные пакеты планирования, так и индивидуальные услуги. Вы можете довериться нам во всей организации мероприятия или выбрать только те услуги, которые вам необходимы.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							3. Как я могу получить цену на мое мероприятие?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Чтобы получить ценовое предложение, свяжитесь с нами по телефону или электронной почте. Мы обсудим детали вашего мероприятия и ваши конкретные потребности, чтобы предоставить вам индивидуальное предложение.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							4. Предоставляете ли вы услуги общественного питания?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Да, мы сотрудничаем с лучшими поставщиками общественного питания, предлагая услуги общественного питания, включающие индивидуальные варианты меню на любой вкус и диетические потребности.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							5. Могу ли я увидеть примеры прошлых мероприятий, которые вы организовали?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Определенный! Мы можем предоставить вам портфолио предыдущих мероприятий, которые мы организовали, чтобы дать вам представление о нашей работе и возможностях.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							6. Как вы управляете оформлением и стилем мероприятия?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Наша команда опытных дизайнеров работает вместе с вами, чтобы создать тему и стиль, отражающий ваше видение. Мы предлагаем услуги цветочного дизайна, индивидуальный декор, профессиональное освещение и многое другое.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							7. Предлагаете ли вы поддержку во время мероприятия?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Да, наша команда присутствует на месте во время мероприятия, чтобы гарантировать, что все пройдет гладко. Мы координируем поставщиков, управляем графиком и решаем любые вопросы, которые могут возникнуть.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Cta>
			<Override slot="text" font="--lead">
				<Strong>
					ГОТОВЫ СПЛАНИРОВАТЬ СВОЁ ИДЕАЛЬНОЕ МЕРОПРИЯТИЕ?
					<br />
				</Strong>
				{"\n"}НА МЕРОПРИЯТИЯХ STARGAZE НАША СТРАСТЬ — СДЕЛАТЬ ВАШЕ ОСОБОЕ СОБЫТИЕ НЕЗАБЫВАЕМЫМ. СВЯЖИТЕСЬ С НАМИ СЕГОДНЯ, ЧТОБЫ НАЧАТЬ ПЛАНИРОВАТЬ ВАШЕ МЕРОПРИЯТИЕ С НАШЕЙ КОМАНДОЙ ЭКСПЕРТОВ.
			</Override>
		</Components.Cta>
		<Components.Footer>
			<Override slot="text" />
			<Override slot="text3" />
			<Override slot="text2" />
			<Override slot="text4" />
			<Override slot="text5" />
			<Override slot="text1" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="text6" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65bb720efdcae00021dd9fe2"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});